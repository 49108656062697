"use client";

import { useRapid } from "@yahoo-creators/i13n";
import { lazy, useState, type FC } from "react";
import { useIntl } from "react-intl";
import { type PrivacyEntry } from "../lib/privacy";
import { ConsentLibrary } from "./ConsentLibrary";
import { FooterLink } from "./FooterLink";
import { PrivacyLink } from "./PrivacyLink";
import { SocialLinks } from "./SocialLinks";

type SVG = React.FunctionComponent<React.SVGAttributes<SVGElement>>;

interface Props {
  acookieFields?: string;
  context: {
    lang: string;
    partner: string;
    site: string;
  };
  links: {
    category?: { text: string; url: string }[];
    corp?: { text: string; url: string }[];
    homepage?: { text: string; url: string }[];
  };
}

const Logos: Record<string, SVG> = {
  creators: lazy(
    () =>
      import(
        "../../../apps/content/src/components/creators/logo/yahooCreatorsLogo.svg"
      ),
  ),
  entertainment: lazy(() => import("../svg/logos/entertainment.svg")),
  frontpage: lazy(() => import("../svg/logos/frontpage.svg")),
  lifestyle: lazy(() => import("../svg/logos/lifestyle.svg")),
  news: lazy(() => import("../svg/logos/news.svg")),
  tech: lazy(() => import("../svg/logos/tech.svg")),
};

export const Footer: FC<Props> = ({
  acookieFields,
  context: { lang, partner, site },
  links,
}) => {
  useRapid("footer");
  const intl = useIntl();
  const [privacyEntries, setPrivacyEntries] = useState<PrivacyEntry[]>([]);
  const Logo = Logos[site] || Logos.news;

  const baseUrl = `https://creators.yahoo.com/`;

  return (
    <>
      <footer id="footer" className="relative bg-[#f1f1f5]">
        <div className="px-5 py-10 md:mx-auto md:max-w-[1540px] md:p-[50px]">
          {/* TODO: Fix */}
          {/* eslint-disable-next-line react/forbid-elements */}
          <a
            className="inline-block"
            href={site !== "frontpage" ? `${baseUrl}/${site}` : baseUrl}
          >
            <Logo
              aria-hidden={false}
              aria-label={intl.formatMessage(
                { id: "footer.A11Y_LOGO" },
                { site },
              )}
              height={25}
              role="img"
              width={196}
            />
          </a>
          <p className="my-6 text-base">
            {intl.formatMessage({ id: "creators.DISCLAIMER" })}
          </p>
          <div className="flex flex-col flex-nowrap md:flex-row">
            <ul className="mb-3 columns-1 gap-x-4">
              {links.category?.map((link, index) => (
                <li key={index}>
                  <FooterLink href={link.url}>{link.text}</FooterLink>
                </li>
              ))}
            </ul>
            <hr className="bg-dirty-seagull md:hidden" />
            <ul className="mt-3 columns-2 gap-x-4 md:ml-20 md:mt-0 md:columns-1">
              {privacyEntries.map(([key, link]) => (
                <li key={key}>
                  <PrivacyLink link={link} />
                </li>
              ))}
              {links.corp?.map((link, index) => (
                <li key={index}>
                  <FooterLink href={link.url}>{link.text}</FooterLink>
                </li>
              ))}
            </ul>
            <SocialLinks site={site} />
          </div>
          <div className="mt-[50px] text-sm leading-3 text-black">
            {/* TODO: Fix this hardcoded year */}
            {intl.formatMessage(
              { id: "footer.COPYRIGHT" },
              { year: new Date().getFullYear() },
            )}
          </div>
        </div>
      </footer>
      <ConsentLibrary
        acookieFields={acookieFields}
        lang={lang}
        onLoadPrivacyEntries={setPrivacyEntries}
        partner={partner}
      />
    </>
  );
};
